import React, {useState} from 'react'
import { Link } from "gatsby"
import { useSwipeable} from 'react-swipeable'

const Testimonials = () => {


    const [slide, setSlide] = useState(0);
    const numSlides = 8;
    const slideWidth = 100 / numSlides;


    const handlers = useSwipeable({
        onSwipedLeft: () => nextSlide(),
        onSwipedRight: () =>  previousSlide(),
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
      });


    const nextSlide = () => {

        slide === numSlides -1 ? setSlide(0) : setSlide(slide + 1)

    }

    const previousSlide = () => {

        slide === 0 ? setSlide(numSlides - 1) : setSlide(slide - 1)

    }

    return(

        <section className="testimonials"> 
                
         
  

            <div className="testimonial-container">
                <div className="inner" {...handlers}>
                    <h2>Testimonials</h2>
                    <div className="slide-holder">
                        <div className="testimonials" style={{width:100*numSlides+"%",left: "-"+ slide*100 +"%"}}>
                            <div className="testimonial" style={{width:slideWidth+"%"}}>
                                <div className="text-holder">
                                    <h4><a href="https://www.jpca.org.nz" target="_blank" rel="noopener noreferrer">John Packham Chartered Accountant</a></h4>
                                    <p>I have grown my practice income by 351% since hiring Dave Wylie and his company Experience on Tap Limited.</p>

                                    <p>Dave has been my business mentor and coach for the past 9 years.. <Link className="read-more" to="/story-john-packham/">Read More</Link></p>

                                </div>
                                

                                
                            </div>

                            <div className="testimonial" style={{width:slideWidth+"%"}}>
                                <div className="text-holder">
                                    <h4><a href="https://www.pharmatech.co.nz" target="_blank" rel="noopener noreferrer" >Peter Lehrke - Pharmaceutical Technologies Ltd.</a></h4>
                                    <p>I now feel like I am back in control of my business.</p>

                                    <p>Working with Dave has shown me the benefit of strategic thinking, rather than tactical reacting. When you are clear about why you are in business, and then what business you are really in, it becomes much easier to know how to achieve your business and personal goals. .. <Link className="read-more" to="/story-peter-lehrke/">Read More</Link></p>
                                </div>
                                
                            </div>

                            <div className="testimonial" style={{width:slideWidth+"%"}}>
                                <div className="text-holder">
                                    <h4><a href="https://www.beejays.co.nz" target="_blank" rel="noopener noreferrer" >Sean Leigh - Beejay Group</a></h4>
                                    <p>This is an opportunity to thank you Dave for all the care and attention you have put into me for the last couple of years, of all the challenges we have thrown at each other. I have always had the feeling that ‘I’ am at the forefront of your mind, if I am right and comfortable in the decisions I make in life then the Business Opportunities will follow - along with the Business Growth... <Link className="read-more" to="/story-sean-leigh/">Read More</Link></p>
                                </div>
                                
                            </div>
                            
                            <div className="testimonial" style={{width:slideWidth+"%"}}>
                                <div className="text-holder">
                                    <h4><a href="http://www.maintaintoprofit.co.nz" target="_blank" rel="noopener noreferrer" >Mark Trafford - Maintain To Profit</a></h4>
                                    <p>Dave Wylie has been working with our team of license owners at MTP for some time now. Dave provides a coaching/mentoring facility that complements our brand and enhances our offer to our owners. He brings maturity and a wealth of business knowledge and the ability to push our owners to perform at constantly higher levels. We highly recommend Dave and Experience On Tap.</p>
                                </div>
                                
                            </div>
                
                            <div className="testimonial" style={{width:slideWidth+"%"}}>
                                <div className="text-holder">
                                    <h4><a href="https://www.activeplus.co.nz/" target="_blank" rel="noopener noreferrer" >Erin Holland - Active+</a></h4>
                                    <p>Dave brings a wealth of business experience to the table and he has been invaluable for his insight, experience, and ability to investigate my business from an outside perspective.. <Link className="read-more" to="/story-erin-holland/">Read More</Link></p>
                                </div>
                                
                            </div>

                            <div className="testimonial" style={{width:slideWidth+"%"}}>
                                <div className="text-holder">
                                    <h4><a href="https://www.hardcasecontainerhire.co.nz/" target="_blank" rel="noopener noreferrer" >Pete Aalbers - Hardcase Container Hire</a></h4>
                                    <p>My company was languishing in a slow growth mode in the Covid-19 year, and soon to run short of container stock. I needed funding and the bank had always been reluctant for funding in the past. <Link className="read-more" to="/story-pete-aalbers/">Read More</Link></p>
                                </div>
                                
                            </div>

                            <div className="testimonial" style={{width:slideWidth+"%"}}>
                                <div className="text-holder">
                                    <h4><a href="https://www.acebs.co.nz" target="_blank" rel="noopener noreferrer" >Eric Neal - Ace Business Solutions</a></h4>
                                    <p>Using Dave Wylie of Experience on Tap as that extra pair of eyes is invaluable. Bouncing ideas off Dave and drawing on his vast business experience can result in huge benefits. The thing I really like about working with Dave, is that Experience on Tap doesn’t lock you into a big contract. <Link className="read-more" to="/story-eric-neal/">Read More</Link></p>
                                </div>
                                
                            </div>

                            <div className="testimonial" style={{width:slideWidth+"%"}}>
                                <div className="text-holder">
                                    <h4><a href="https://www.embroidme.co.nz/east-tamaki" target="_blank" rel="noopener noreferrer" >Wayne Strong - EmbroidMe East Tamaki</a></h4>
                                    <p>EmbroidMe East Tamaki have used Experience on Tap staff for a number of different projects over the last 15 years. These include Business Coaching, Special Projects, Planned business growth strategies and Sales Training. <Link className="read-more" to="/story-wayne-strong/">Read More</Link></p>
                                </div>
                                
                            </div>
            
                        </div>

                    </div>
                   
                    <nav>
                        <button className={`story-1 nav-btn ${slide === 0 ? 'current' : ''}`} onClick={() => setSlide(0)}></button>
                        <button className={`story-2 nav-btn ${slide === 1 ? 'current' : ''}`} onClick={() => setSlide(1)}></button>
                        <button className={`story-3 nav-btn ${slide === 2 ? 'current' : ''}`} onClick={() => setSlide(2)}></button>
                        <button className={`story-4 nav-btn ${slide === 3 ? 'current' : ''}`} onClick={() => setSlide(3)}></button>
                        <button className={`story-5 nav-btn ${slide === 4 ? 'current' : ''}`} onClick={() => setSlide(4)}></button>
                        <button className={`story-6 nav-btn ${slide === 5 ? 'current' : ''}`} onClick={() => setSlide(5)}></button>
                        <button className={`story-5 nav-btn ${slide === 6 ? 'current' : ''}`} onClick={() => setSlide(6)}></button>
                        <button className={`story-6 nav-btn ${slide === 7 ? 'current' : ''}`} onClick={() => setSlide(7)}></button>
                  </nav>  
                </div>
            
            </div>
            <div className="image">

            </div>
            
        </section>


    )
}

export default Testimonials